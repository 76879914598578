
// Author's custom styles
// we need to import this first so that it would override bootstrap scss variables later
@import "variables";

// Bootstrap library
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-icons/font/bootstrap-icons.css";

@import "../components/footer/footer";
@import "../components/fund/promo";
@import "carousel";
@import "subscribe-block";
@import "quicklinks";
@import "subscribe-bar";
@import "fund-performance";
@import "people";
@import "menu";
@import "page";
@import "funds-promo";

.recaptcha-notice {
    text-align: center;
}

.btn {
    text-transform: uppercase;
}

.btn-primary {
    @include button-variant($primary, $primary, $secondary);
}

a{
    hyphens: none;
}

.scroller .scroller-item{
    max-height: 175px;
}
.blue-table{
    thead tr{
        background-color: #014BC4;
        color:#fff;
        th{
            padding: 8px;
        }
    }
    tbody tr{
        color:#fff;
        td{
            padding: 8px;
        }
        &:nth-child(odd){
            background-color: #000B44;
        }
        &:nth-child(even){
            background-color: #00245E;
        }
    }
}